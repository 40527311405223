import React from 'react';
import {PageColor} from '../components/page-color';
import {CaseTile} from '../components/case-tile';

export default class Cases extends React.Component {
    constructor() {
        super();
        this.state = {};
    }

    moveRight() {
        document.querySelector('.cases').scrollBy({left: 1000, behavior: 'smooth'});
    }

    moveLeft() {
        document.querySelector('.cases').scrollTo(0, 0);
    }

    render = () => (
        <main>
            <PageColor color="tan" nav="white"/>
            <div className="cases">
                {this.props.pageContext.website.cases.map(_case => <CaseTile _case={_case}/>)}

                <div className="left-control">
                    <a onClick={this.moveLeft}>
                        <img className="cases-control" src="/images/cases/previous.png"/>
                    </a>
                </div>

                <div className="right-control">
                    <a onClick={this.moveRight}>
                        <img class="cases-control" src="/images/cases/next.png"/>
                    </a>
                </div>
            </div>
            <div className="text bg-white p-14 text-center text-2xl leading-6	">
               <span className="text-orange-500">We’re result-driven, meaning:</span> we strive for the best results (im)possible! We think the unimaginary and
                go beyond. For more than 50 prestigious brands, we delivered striking results and succeeded in boosting
                their digital story. Let us show you a glimpse of the creative, social stories we brought to life.
            </div>
        </main>
    );
}
